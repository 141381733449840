/* Colors */
:root {
    --color-primary-50: #F8FAFF;
    --color-primary-100: #EDF4FF;
    --color-primary-200: #CDD7FF;
    --color-primary-300: #A1B4FF;
    --color-primary-400: #6783F5;
    --color-primary-500: #4164F2;
    --color-primary-600: #0D39C9;
    --color-primary-700: #052A77;
    --color-primary-800: #081D49;
    --color-primary-900: #1C1F30;
    --color-primary-950: #020617;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

@font-face {
    font-family: 'EuclidCircularA';
    font-style: normal;
    font-weight: normal;
    src: url('./assets/fonts/EuclidCircularA-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'EuclidCircularA';
    font-style: normal;
    font-weight: 500;
    src: url('./assets/fonts/EuclidCircularA-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'EuclidCircularA';
    font-style: normal;
    font-weight: bold;
    src: url('./assets/fonts/EuclidCircularA-Bold.otf') format('opentype');
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

input[type="search" i]::-webkit-search-cancel-button {
    display: none;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 1;
    color: theme('colors.gray.400');
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    opacity: 1;
    color: theme('colors.gray.400');
}

textarea {
    /* border: none; */
    background-color: transparent;
    resize: none;
    outline: none;
}

input::placeholder,
textarea::placeholder {
    font-size: 14px;
    opacity: 1;
    color: theme('colors.gray.400');
}

.dark input::-moz-placeholder,
textarea::-moz-placeholder {
    font-size: 14px;
    opacity: 1;
    color: theme('colors.gray.600');
}

.dark input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    font-size: 14px;
    opacity: 1;
    color: theme('colors.gray.600');
}

.dark input::placeholder,
.dark textarea::placeholder {
    font-size: 14px;
    opacity: 1;
    color: theme('colors.gray.600');
}

input[type=number],
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
}

html,
body {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

body {
    font-family: 'EuclidCircularA', sans-serif;
    font-size: 16px;
    color: theme('colors.gray.900');
}

.dark body {
    color: theme('colors.gray.50');
    background: theme('colors.gray.950');
}

input,
select {
    font-size: 100%;
}

*:focus {
    outline: none;
}

.text-label {
    color: theme('colors.gray[500]');
    @apply text-xs font-bold uppercase;
}

.page {
    min-height: 100vh;
    /* @apply bg-gray-50 dark:bg-gray-800; */
}

a {
    cursor: pointer;
}

a:hover {
    text-decoration: underline
}

.fill {
    fill: currentColor;
}

/* Editor.js Container */

/* Paragraph */
.editorjs-container {
    @apply space-y-4 text-base leading-normal;
}
.editorjs-container p {
    @apply leading-normal break-words;
}

/* Header */
.editorjs-container h1 {
    @apply text-3xl font-bold;
}

.editorjs-container h2 {
    @apply text-2xl font-bold;
}

.editorjs-container h3 {
    @apply text-xl font-bold;
}

/* List */
.editorjs-container ul {
    @apply pl-4 list-disc space-y-2 list-inside;
}
.editorjs-container ol {
    @apply pl-4 list-decimal space-y-2 list-inside;
}

/* Warning */
.editorjs-container .cdx-warning {
    @apply p-4 bg-yellow-100 border-l-4 border-yellow-400 rounded-md;
}

.editorjs-container .cdx-warning__title {
    @apply mb-1 font-bold text-yellow-800;
}

.editorjs-container .cdx-warning__message {
    @apply text-yellow-700;
}

/* Code Block */
.editorjs-container pre {
    @apply p-2 overflow-x-auto font-mono text-gray-100 bg-gray-800 rounded;
}

.editorjs-container code {
    @apply px-2 py-1 font-mono text-gray-100 rounded;
}

/* Simple Video */
.editorjs-container .simple-video {
    @apply w-full rounded-md;
}

.editorjs-container .simple-video__title {
    @apply mt-2;
}

/* Simple Audio */
.editorjs-container .simple-audio {
    @apply w-full p-4 bg-gray-100 rounded-md;
}

.editorjs-container .simple-audio__title {
    @apply mt-2 text-gray-500;
}

/* Image */
.editorjs-container img {
    @apply w-full rounded-md;
}

.editorjs-container figcaption {
    @apply mt-1 text-center text-gray-500;
}

/* Quote */
.editorjs-container blockquote {
    @apply pl-4 mb-4 italic text-gray-600 border-l-4 border-gray-300;
}

.editorjs-container .cdx-quote__author {
    @apply mt-2 text-right text-gray-700;
}

/* Marker */
.editorjs-container .cdx-marker {
    @apply px-1 text-gray-800 bg-yellow-200 rounded;
}

/* Delimiter */
.editorjs-container .cdx-delimiter {
    @apply my-8 text-center text-gray-500;
}

/* Inline Code */
.editorjs-container .cdx-inline-code {
    @apply px-1 font-mono text-gray-800 bg-gray-100 rounded;
}

/* Underline */
.editorjs-container .cdx-underline {
    @apply underline;
}

/* Hyperlink */
.editorjs-container a {
    @apply text-blue-500 underline hover:text-blue-700;
}

.codex-editor {
    padding: 12px 24px
}

.editor-view-only .ce-block__content {
    max-width: none;
}

.cdx-simple-video video {
    aspect-ratio: 16 / 9;
}

.editorjs-container .ce-delimiter {
    @apply transition h-px my-8 bg-gray-500 border-0 dark:bg-gray-500 p-0;
}
.editorjs-container .ce-delimiter::before {
    display: none;
}
@media (max-width: 650px) {
    .dark .ce-toolbar__plus, .dark .ce-toolbar__settings-btn {
        @apply bg-gray-900 text-gray-50 border-none;
    }

    .ce-popover .ce-popover__container {
        @apply bottom-12 rounded-b-none;
    }
}


.dark .ce-toolbar__plus, .dark .ce-toolbar__settings-btn {
    color: #fff;
}
input:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 50px white inset;
    box-shadow: 0 0 0 50px white inset;
}

.link {
    cursor: pointer;
}

.link:hover {
    text-decoration: underline;
}

.link-primary {
    @apply text-primary-500
}

.dark .link-primary {
    @apply text-primary-500
}

/* Dark Mode transition */
::view-transition-group(root) {
  animation-timing-function: var(--expo-out);
}

::view-transition-new(root) {
  mask: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><defs><filter id="blur"><feGaussianBlur stdDeviation="2"/></filter></defs><circle cx="0" cy="0" r="18" fill="white" filter="url(%23blur)"/></svg>') top left / 0 no-repeat;
  mask-origin: content-box;
  animation: scale 1s;
  transform-origin: top left;
}

::view-transition-old(root),
  .dark::view-transition-old(root) {
  animation: scale 1s;
  transform-origin: top left;
  z-index: -1;
}

@keyframes scale {
  to {
    mask-size: 350vmax;
  }
}
